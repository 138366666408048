import { Box, Flex, Text } from "rebass/styled-components";
import { Swiper } from "swiper/react";
import { Mousewheel, Pagination, Navigation } from "swiper/modules";
import { StyledSwiperSlide } from "../styledComponents";
import { ContentItem } from "../ContentItem";
import { IContentItem, IEmptyContentItem } from "../@types";
import { Loading } from "../Loading";
import styled, { css } from "styled-components";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { ReactNode } from "react";
import theme from "../../../../theme/themeThree";
import { EmptyContentItem } from "../EmptyContentItem";
import { Pinnable } from "../../../Components/Pinnable";
import { SwiperOptions } from "swiper/types";

interface IContentFeed {
  data: IContentItem[] | IEmptyContentItem[];
  loading?: boolean;
  title: string;
  displayTitle?: boolean;
  displayArrows?: boolean;
  icon?: ReactNode;
  scrollable?: boolean;
  subtextColor?: string;
  borderColor?: string;
  breakpoints?:
    | {
        [width: number]: SwiperOptions;
        [ratio: string]: SwiperOptions;
      }
    | undefined;
  controlsColor?: string;
  isOwnProfile?: boolean;
}

const PageableContentFeed = ({
  data,
  loading,
  displayTitle,
  displayArrows,
  title,
  isOwnProfile,
  icon,
  breakpoints,
  subtextColor,
  scrollable,
  controlsColor = theme.colors.envy,
  borderColor,
}: IContentFeed) => {
  if (loading) return <Loading />;
  if (!data) return <></>;

  return (
    <Box style={{ position: "relative" }}>
      <Flex alignItems="center" mb={4} style={{ position: "relative" }}>
        {displayTitle && (
          <Text variant="text.titleSmall">
            {icon}
            {title}
          </Text>
        )}

        {displayArrows && (
          <ArrowWrapper>
            <StyledLeftArrow className="swiper-button-prev" />
            <StyledRightArrow className="swiper-button-next" />
          </ArrowWrapper>
        )}
      </Flex>
      <Box
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <PageableContentFeedCarousel
          data={data}
          title={title}
          controlsColor={controlsColor}
          isOwnProfile={isOwnProfile}
          breakpoints={breakpoints}
          subtextColor={subtextColor}
          scrollable={scrollable}
          borderColor={borderColor}
        />
      </Box>
    </Box>
  );
};

const PageableContentFeedCarousel = ({
  data,
  title,
  isOwnProfile,
  breakpoints,
  subtextColor,
  scrollable,
  controlsColor = theme.colors.envy,
  borderColor,
}: IContentFeed) => (
  <StyledSwiper
    centeredSlides={false}
    spaceBetween={16}
    slidesPerView={1}
    mousewheel={scrollable}
    navigation={{
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    }}
    pagination={{
      clickable: true,
    }}
    breakpoints={breakpoints}
    modules={[Mousewheel, Pagination, Navigation]}
    controlsColor={controlsColor}
  >
    {data.map((item: IContentItem | IEmptyContentItem, index: number) => (
      <Slide key={index}>
        {item.type === "empty" ? (
          <EmptyContentItem item={item} />
        ) : (
          <Pinnable
            clipId={isOwnProfile ? item.clipId : ""}
            userId={item.user._id}
            hasBeenPinned={!!item.hasBeenPinned}
          >
            <ContentItem
              item={item}
              title={title}
              subtextColor={subtextColor}
              borderColor={borderColor}
            />
          </Pinnable>
        )}
      </Slide>
    ))}
  </StyledSwiper>
);

const Slide = styled(StyledSwiperSlide)`
  margin-bottom: 50px;
`;
const StyledSwiper = styled(Swiper)<{ controlsColor: string }>`
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    opacity: 0.2;
    background: ${({ controlsColor }) => controlsColor};
    position: relative;
    bottom: 0px;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: ${({ controlsColor }) => controlsColor};
  }

  .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-next:after {
    display: none;
  }
`;

const StyledArrowCss = css`
  width: 28px;
  height: 28px;
  color: ${(props) => {
    return props.theme.colors.ice;
  }};
`;
const StyledLeftArrow = styled(HiChevronLeft)`
  ${StyledArrowCss};
`;

const StyledRightArrow = styled(HiChevronRight)`
  ${StyledArrowCss};
`;
const ArrowWrapper = styled(Flex)`
  right: 0px;
  top: 0px;
  z-index: 1;
  position: absolute;
  width: 81px;
  height: 50px;
`;

export { PageableContentFeed };
