import { useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Flex, Text } from "rebass/styled-components";
import styled, { css } from "styled-components";
import videojs from "video.js";

import { ClipExperienceViewModel } from "./ViewModel";
import { VideoPlayer } from "MVVM/VideoPlayer";
import { countClipImpression } from "MVVM/Utilities/Clip";
import { InfoSection } from "./InfoSection";
import { Divider } from "MVVM/Components/Divider";
import { ShareSection } from "./ShareSection";
import { Feed } from "./Feed";
import { Tabs } from "MVVM/Components/Tabs";
import { FeedType } from "./@types";
import CloseButton from "components/atoms/form/button/close";
import { Button } from "MVVM/Components/Button";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { EClipPageSource } from "MVVM/@types";
import { CreatorCards } from "./CreatorCards";

interface ClipExperienceProps {
  clipId?: string;
  source: EClipPageSource;
}

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const ClipExperience = ({ clipId = "", source }: ClipExperienceProps) => {
  const { id } = useParams<{ id: string }>();
  const videoPlayerRef = useRef<videojs.Player | null>(null);
  const history = useHistory();
  const location = useLocation<{ autoplay: boolean | undefined }>();
  const autoplay = location.state?.autoplay;
  const { data, error, loading } = ClipExperienceViewModel({
    clipIdentifier: id || clipId,
  });

  if (loading || !data?.getClip) return <></>;
  if (error || !data?.getClip)
    return <Text fontWeight={500}>Something went wrong.</Text>;

  window.history.replaceState({}, "");

  const clip = data.getClip;
  const tabContent = [];

  switch (source) {
    case EClipPageSource.DIRECT:
      tabContent.push(
        {
          title: "Recommended",
          component: <Feed type={FeedType.ForYou} />,
        },
        { title: "Creator Cards", component: <CreatorCards clip={clip} /> },
      );
      break;
    case EClipPageSource.MODAL:
      tabContent.push({
        title: "Creator Cards",
        component: <CreatorCards clip={clip} />,
      });
      break;
    default:
      break;
  }

  return (
    <ClipWrapper
      style={{
        background: `url(${REACT_APP_S3_CLIP_BUCKET}/${clip?.clipImageSource}) 0 0 no-repeat`,
      }}
    >
      {[EClipPageSource.FEED].includes(source) && (
        <StyledCloseButton
          size="small"
          classification="round"
          onClick={history.goBack}
        />
      )}
      <ClipContainer style={{ backdropFilter: "blur(14px)" }}>
        <VideoContainer>
          <Box
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            {[EClipPageSource.FEED].includes(source) && (
              <>
                <StyledButtonUp
                  size="small"
                  classification="round"
                  onClick={history.goBack}
                  flex={true}
                >
                  <FaChevronUp />
                </StyledButtonUp>
                <StyledButtonDown
                  size="small"
                  classification="round"
                  onClick={history.goBack}
                  flex={true}
                >
                  <FaChevronDown />
                </StyledButtonDown>
              </>
            )}
            <VideoPlayer
              mp4Source={`${REACT_APP_S3_CLIP_BUCKET}/${clip?.clipLink}`}
              refForVideoPlayer={videoPlayerRef}
              onPlayStart={() => countClipImpression(clip.shareId)}
              playAnalyticsProperties={{
                shareId: clip.shareId,
                clipId: clip._id,
                clipOwner: clip.user,
                total_views: clip.views,
                game: clip.game,
              }}
              watchAnalyticsProperties={{
                collection: "Clip Experience",
                collectionType: "Generic",
                item: clip.shareId,
                itemType: "Clip",
                action: "watch",
              }}
              options={{
                autoplay: autoplay ? "play" : "muted",
                loop: true,
              }}
            />
          </Box>
        </VideoContainer>
        <Sidebar>
          <InfoSection clip={clip} />
          <Divider />
          <ShareSection clip={clip} />
          <Divider />
          <ColumnContainer>
            <Tabs tabs={tabContent} />
          </ColumnContainer>
        </Sidebar>
      </ClipContainer>
    </ClipWrapper>
  );
};

const ClipWrapper = styled(Box)`
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    height: auto;
  }
`;

const ColumnContainer = styled(Box)`
  flex-grow: 1;
  height: 0;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    height: 100%;
    padding-bottom: 80px;
  }
`;

const ClipContainer = styled(Flex)`
  height: 100%;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    flex-direction: column;
  }
`;

const Sidebar = styled(Flex)`
  background: ${({ theme }) => theme.colors.darkerNed};
  flex-direction: column;
  overflow: auto;
  width: 441px;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    flex-grow: 1;
    width: 100%;
  }
`;

const roundButtonStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  opacity: 0.8;
  position: absolute;
  transform: translate(-50%, 0%);
  z-index: 2;

  :hover {
    background: ${({ theme }) => theme.colors.darkerNed};
    color: ${({ theme }) => theme.colors.ice};
    opacity: 0.6;
  }
`;

const StyledButtonUp = styled(Button)`
  ${roundButtonStyles}
  top: 12px;
`;

const StyledButtonDown = styled(Button)`
  ${roundButtonStyles}
  bottom: 12px;
`;

const StyledCloseButton = styled(CloseButton)`
  left: 12px;
  position: absolute;
  top: 12px;
  z-index: 2;

  :hover {
    background: ${({ theme }) => theme.colors.darkerNed};
    color: ${({ theme }) => theme.colors.ice};
  }

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    display: none;
  }
`;

const VideoContainer = styled(Flex)`
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  position: relative;

  ::before {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
`;

export { ClipExperience };
