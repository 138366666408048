import { gql } from "@apollo/client";

export const GET_CLIP = gql`
  query GetClip($clipIdentifier: String) {
    getClip(clipIdentifier: $clipIdentifier) {
      _id
      clipImageSource
      clipImageThumb
      clipLink
      clipSnapshot
      clipTitle
      createdDate
      shareId
      userClipTitle
      views

      clipProperties {
        _id
        displayType
        key
        category
        value
      }

      comments {
        count
        reaction
      }

      personalizations {
        cards {
          facet {
            type
          }

          ui {
            title
            category
            rarity
          }
        }
      }

      user {
        _id
        avatar
        username
      }
    }
  }
`;
