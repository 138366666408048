import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";

import Remix from "components/atoms/form/button/remix";
import { ModalType } from "MVVM/@types";
import { ClipReactions } from "MVVM/Components/ClipReactions";
import { AllstarModalState } from "MVVM/State/modals";
import { canRemix } from "MVVM/Utilities/Clip";
import { TClip, ICommentable } from "MVVM/@types";
import { Button } from "MVVM/Components/Button";
import { RiShareForwardFill } from "react-icons/ri";
import { CopyLink } from "MVVM/Components/Button/Share";
import { More } from "MVVM/Components/Clip/Components/ClipOptions/Components/MoreClipOptionsDropdown";
import { useInteractonInterceptor } from "MVVM/Hooks/useInteractionInterceptor";
import { EPaywallType } from "MVVM/Components/Modals/Paywall";
import { trackClipDownload } from "utils";

interface IShareSectionProps {
  clip: TClip & ICommentable;
}

const ShareSection = ({ clip }: IShareSectionProps) => {
  const isFeed = false; // @TODO

  const setAllstarModalState = useSetRecoilState(AllstarModalState);
  const checkFeatureAccessQuery = useInteractonInterceptor();
  const toggleModal = (modalType: ModalType) => {
    setAllstarModalState({
      data: clip,
      functions: {},
      isOpen: modalType,
    });
  };
  const setReportModalOpen = () => {
    setAllstarModalState({
      data: { _id: clip._id, user: { _id: clip.user._id } },
      functions: {},
      isOpen: ModalType.Report,
    });
  };

  const handleDownloadClick = async () => {
    const downloadLink = `${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clipLink}`;

    const { data } = await checkFeatureAccessQuery({
      variables: { feature: "FREE_ENGAGED_DOWNLOAD" },
    });

    if (data.checkFeatureAccess) {
      return setAllstarModalState({
        isOpen: ModalType.Download,
        data: {
          type: EPaywallType.download as EPaywallType,
        },
        functions: {},
      });
    }
    trackClipDownload(clip);
    window.open(downloadLink);
  };

  return (
    <Flex style={{ gap: 12 }} px={6}>
      {canRemix({ ...clip, isMontage: false }) && (
        <Remix
          classification={undefined}
          classificiation={undefined}
          clip={clip}
          disableOnClick={false}
          isFeed={isFeed}
          onClick={undefined}
          setModalOpen={() => toggleModal(ModalType.Remix)}
        />
      )}
      <StyledClipReactions clip={clip} />
      <CopyLink
        clip={clip}
        clipLink={`${window.location.origin}/clip/${clip.shareId}`}
        onClick={undefined}
        isMontage={false}
        isProfile={false}
      ></CopyLink>
      <StyledShareButton
        classification="secondaryRounded"
        onClick={() => toggleModal(ModalType.Share)}
      >
        <StyledShareIcon size={20} />
      </StyledShareButton>
      <More
        clip={clip}
        setShareModalOpen={() => toggleModal(ModalType.Share)}
        setReportModalOpen={setReportModalOpen}
        onDownloadClick={handleDownloadClick}
      />
    </Flex>
  );
};

const StyledShareButton = styled(Button)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.envy};
  display: flex;

  :hover {
    background-color: ${({ theme }) => theme.colors.darkerEnvy};
  }
`;

const StyledShareIcon = styled(RiShareForwardFill)``;

const StyledClipReactions = styled(ClipReactions)`
  min-height: 40px;
  width: 80px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 36px;
    width: 65px;
  }
`;

export { ShareSection };
