import { Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

import { TClip } from "MVVM/@types";
import { RarityText } from "MVVM/Components/Text/RarityText";
import { CreatorCardCategoryIcon } from "MVVM/Components/Icons/CreatorCardCategoryIcon";
import { Link } from "react-router-dom";
import { analytics } from "MVVM/Analytics";

interface ICreatorCardsProps {
  clip: TClip;
}

const CreatorCards = ({ clip }: ICreatorCardsProps) => {
  const uniqueCards = clip.personalizations.cards.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => t.ui.title === obj.ui.title),
  );

  return (
    <CreatorCardContainer>
      {uniqueCards.map((card) => {
        return (
          <Link
            to={{ pathname: `/studio?type=["${card.facet.type[0]}"]` }}
            target="_blank"
            key={card._id}
            style={{ color: "unset", textDecoration: "none" }}
            onClick={() =>
              analytics.track("Clip Page - Creator Card Click", {
                type: "Creator Card List",
              })
            }
          >
            <CreatorCardItem>
              <CreatorCardCategoryIcon
                category={card.facet.type[0]}
                style={{ borderRadius: "8px", padding: "8px" }}
              />
              <Flex flexDirection="column" justifyContent="space-between">
                <Text fontWeight={500}>{card.ui.title}</Text>
                <RarityTextContainer>
                  <RarityText rarity={card.ui.rarity} />
                </RarityTextContainer>
              </Flex>
            </CreatorCardItem>
          </Link>
        );
      })}
    </CreatorCardContainer>
  );
};

const RarityTextContainer = styled(Flex)`
  align-items: center;
  gap: 4px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const CreatorCardItem = styled(Flex)<{ active?: boolean }>`
  color: reset;
  gap: 18px;
  padding: 12px;
  width: 100%;

  :hover {
    background: ${({ theme }) => theme.colors.navy};
    border-radius: 8px;
    cursor: pointer;
  }

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    flex-direction: column;
  }
`;

const CreatorCardContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.darkerNed};
  flex-direction: column;
  padding: 12px;
  width: 100%;
`;

export { CreatorCards };
