import { useHistory } from "react-router-dom";
import { Box, Flex, Text } from "rebass/styled-components";

import { Avatar } from "MVVM/Components/Avatar";
import {
  AvatarWrapper,
  getAvatarBorderLevel,
  getAvatarImage,
} from "MVVM/Utilities/User";
import {
  dateFormatWithDistanceSuffix,
  getClipTitle,
} from "MVVM/Utilities/Clip";
import { FollowButton } from "MVVM/Components/Button";
import { useUser } from "MVVM/Hooks/useUser";
import { AllstarModalState } from "MVVM/State/modals";
import { useRecoilState } from "recoil";
import { ModalType, TClip } from "MVVM/@types";
import { useMutateFollowers } from "MVVM/Hooks/useMutateFollowers";
import { formatNumber } from "MVVM/Views/Wrapped/util";

interface InfoSectionProps {
  clip: TClip;
}

const InfoSection = ({ clip }: InfoSectionProps) => {
  const { allstarUser } = useUser();
  const { mutateFollowers } = useMutateFollowers();
  const username = clip?.user?.username || "";
  const avatarImage = getAvatarImage(clip.user);
  const avatarBorderLevel = getAvatarBorderLevel(clip.user);
  const history = useHistory();
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const handleClick = () => {
    username && history.push(`/u/${username}`);
  };

  const setFollowModal = ({
    displayName,
    avatar,
  }: {
    displayName: string;
    avatar: string;
  }) => {
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.Signup,
      data: {
        target: displayName,
        avatar: avatar,
        action: "follow",
      },
    });
  };

  return (
    <Flex style={{ gap: 18 }} p={6} pb={0}>
      <Box
        onClick={() => handleClick()}
        style={{ cursor: "pointer" }}
        flexShrink={0}
      >
        <AvatarWrapper size="nav" borderLevel={avatarBorderLevel}>
          <Avatar
            image={avatarImage}
            onClick={() => history.push(`/u/${username}`)}
            size="small"
          />
        </AvatarWrapper>
      </Box>
      <Flex flexGrow={1}>
        <Flex flexWrap="wrap" style={{ gap: 14 }} flexGrow={1}>
          <Flex flexDirection="column" style={{ gap: 12 }} flexGrow={1}>
            <Flex justifyContent="space-between" style={{ gap: 2 }}>
              <Text variant="text.title3">{getClipTitle(clip)}</Text>
              <FollowButton
                alignSelf="flex-start"
                allstarUser={allstarUser}
                avatar={clip.user.avatar}
                displayName={username}
                flex={true}
                followUnfollow={mutateFollowers}
                followerData={undefined}
                isModal={false}
                ml={1}
                openModal={true}
                setFollowModal={setFollowModal}
                size="tiny"
                userId={clip.user._id}
              />
            </Flex>
            <Flex style={{ gap: 6 }} color="chalk" flexWrap="wrap">
              <Text onClick={() => handleClick()} style={{ cursor: "pointer" }}>
                {username || "Allstar User"}
              </Text>
              <Text>&bull;</Text>
              {!!clip.views && clip.views >= 5 && (
                <>
                  <Text>{formatNumber(clip.views)} views</Text>{" "}
                  <Text>&bull;</Text>
                </>
              )}
              <Text>{dateFormatWithDistanceSuffix(clip.createdDate)}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { InfoSection };
