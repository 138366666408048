import { Text } from "rebass/styled-components";

import { ReactComponent as Mythic } from "../../../Assets/Tiers/Badges/mythic.svg";
import { ReactComponent as Legendary } from "../../../Assets/Tiers/Badges/legendary.svg";
import { ReactComponent as Rare } from "../../../Assets/Tiers/Badges/rare.svg";
import { ReactComponent as Premium } from "../../../Assets/Tiers/Badges/premium.svg";
import { ReactComponent as Standard } from "../../../Assets/Tiers/Badges/standard.svg";

const RarityText = ({ rarity }: { rarity: string }) => {
  const rarities = {
    "100": { text: "Standard", icon: <Standard /> },
    "200": { text: "Premium", icon: <Premium /> },
    "300": { text: "Rare", icon: <Rare /> },
    "400": { text: "Legendary", icon: <Legendary /> },
    "500": { text: "Mythic", icon: <Mythic /> },
  };

  return (
    <>
      {rarities[rarity as keyof typeof rarities]?.icon || rarities["100"].icon}
      <Text
        fontWeight={500}
        variant="text.paragraphSmall"
        data-testid="rarity-text"
        color={
          rarities[rarity as keyof typeof rarities]?.text.toLowerCase() ||
          "envy"
        }
      >
        {rarities[rarity as keyof typeof rarities]?.text ||
          rarities["100"].text}
      </Text>
    </>
  );
};

export { RarityText };
