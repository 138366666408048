import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import { CompetitionViewModel } from "../../ViewModel";
import { TComp } from "../../@types";
import { CountDownRenderer } from "../../Views/Play/countdownTimer";
import Countdown from "react-countdown";
import { CompetitionClips } from "../../Views/Play/CompetitionClips";
import { JoinCampaignButton } from "../JoinCampaignButton";
import { useEffect } from "react";

export const CampaignWidget = () => {
  const { data } = CompetitionViewModel({
    activeOnly: true,
  });

  const competitions = data?.competitions as TComp[];

  useEffect(() => {
    if (competitions) {
      const present = competitions?.filter(
        (competition) => parseInt(competition?.campaignEnd) > Date.now(),
      );
      const currentCompetition = present[0];
      if (currentCompetition) {
        window.rudderanalytics.track("Competition Widget - Loaded", {});
      }
    }
  }, [competitions]);
  if (!competitions) return <></>;

  const present = competitions?.filter(
    (competition) => parseInt(competition?.campaignEnd) > Date.now(),
  );
  const currentCompetition = present[0];

  if (!currentCompetition) return <></>;

  return (
    <Container backgroundImage={currentCompetition.images.carouselBackground}>
      <CampaignColumn>
        <CampaignImageAndTimer>
          <Image
            width="300"
            flexShrink={0}
            src={currentCompetition.images.campaign}
            style={{ zIndex: 2 }}
          />
          <Countdown
            date={new Date(parseInt(currentCompetition.campaignJoinEnd))}
            renderer={(props) => (
              <CountDownRenderer {...props} color={currentCompetition.color} />
            )}
          />
        </CampaignImageAndTimer>
        <JoinCampaignButton
          color={currentCompetition.color}
          secondaryColor={currentCompetition.secondaryColor}
          slug={currentCompetition.slug}
        />
      </CampaignColumn>

      <ClipsContainer>
        <CompetitionClips
          cardIds={
            currentCompetition?.creatorCards?.length
              ? currentCompetition?.creatorCards
              : []
          }
          swiperControlsColor={currentCompetition.color}
          title="Competition Widget"
          displayTitle={false}
          displayArrows={false}
          competitionColor={currentCompetition.color}
          swiperBreakpoints={{
            900: {
              slidesPerView: 0,
            },
            950: {
              slidesPerView: 1,
            },
            1200: {
              slidesPerView: 2,
            },
            1400: {
              slidesPerView: 3,
            },
            1700: {
              slidesPerView: 4,
            },
            2000: {
              slidesPerView: 5,
            },
          }}
        />
      </ClipsContainer>
    </Container>
  );
};

const ClipsContainer = styled(Flex)`
  width: 70%;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Container = styled(Flex)<{ backgroundImage: string }>`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center;

    border-radius: 9px;
  }
  width: 100%;
  padding: 20px;
  position: relative;
  justify-content: space-between;
  border-radius: 9px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    &::before {
      background-position: top left; /* Shift the background image */
    }
  }
`;

const CampaignColumn = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  flex-shrink: 0;

  @media (max-width: 900px) {
    justify-content: start;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 600px) {
    justify-content: start;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
`;

const CampaignImageAndTimer = styled(Flex)`
  flex-direction: column;
  gap: 4px;

  @media (max-width: 600px) {
    align-items: center;
  }
`;
